<template>
  <div>{{ rowFormat(row) | handleNumByDot }}</div>
</template>

<script>
export default {
  name: 'NumberCol',
  created() {},
  data() {
    return {}
  },
  filters: {
    handleNumByDot(num) {
      if (!isNaN(Number(num))) {
        const str = new String(num).toString()
        const numberArray = str.split('.')
        const floatNum = numberArray[1] ? '.' + numberArray[1] : ''
        var LongNum = numberArray[0]
        for (var i = 3; i < LongNum.length; i += 4) {
          LongNum =
            LongNum.slice(0, LongNum.length - i) + ',' + LongNum.slice(LongNum.length - i)
        }
        return LongNum + floatNum
      } else {
        return num
      }
    },
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    tableParam: {
      type: Object,
      default: () => {},
    },
    rowFormat: {
      type: Function,
      default: () => {},
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
