
import request from './request'

export function getIpListByApp(v,app_name,st,et,max,baseURL='/api') {
    return request({
      method: 'GET',
      url: `/get_ip_list_by_app`,
      params:{
        v,
        st,
        et,
        app_name,
        max
      },
      baseURL
    })
  }
  
  
  export function getIpList(v,st,et,max) {
    return request({
      method: 'GET',
      url: `/get_ip_list`,
      params:{
        v,
        st,
        et,
        max
      }
    })
  }
  
  
