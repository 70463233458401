<template>
  <div class="container">
    <el-row class="row shadow">
      <el-col :span="18"> <TimeZone @change="dataChange" @changeDateZone="selectDateZone"></TimeZone></el-col>
      <el-col :span="8">
        <el-autocomplete
          class="inline-input"
          v-model="clientIp"
          placeholder="Please input IP address"
          :select-when-unmatched="true"
          :fetch-suggestions="searchIpList"
          @select="searchByIp"
          clearable
        >
          <!-- <el-button slot="append" icon="el-icon-search" @click="searchByIp"></el-button> -->
        </el-autocomplete>
      </el-col>
    </el-row>
    <div v-for="(source, index) in chartData" :key="index">
      <div class="shadow top-title">{{ source.name }}</div>
      <el-row type="flex" justify="space-between">
        <el-col :span="6" class="col line shadow">
          <Chart
            :chartId="`client_first_line${index}`"
            :option="
              new LineOption({
                title: `Flow Per Second of IP ${clientIp}`,
                legendTitle: ['||Min||Max||Avg'],
                ipVersion,
                keepPoint: 0,
              }).getOption(['title', 'xAxis', 'yAxis', 'grid', 'series', 'tooltip', 'legend'])
            "
            :extraOption="lineLegendFlow[source.name]"
            :series="source.flow_result"
            :EventList="{
              legendScroll: handleLegend,
            }"
            :watchSeries="initPage"
          ></Chart>
        </el-col>
        <el-col :span="6" class="col line shadow">
          <Chart
            :chartId="`client_second_line${index}`"
            :option="
              new LineOption({
                title: `Traffic of IP ${clientIp} (Kbps)`,
                yunit: 'Kbps',
                legendTitle: ['||Min||Max||Avg'],
                ipVersion,
                keepPoint: 2,
              }).getOption(['title', 'xAxis', 'yAxis', 'grid', 'series', 'tooltip', 'legend'])
            "
            :extraOption="lineLegendByte[source.name]"
            :series="source.byte_result"
            :EventList="{
              legendScroll: handleLegend,
            }"
            :watchSeries="initPage"
          ></Chart>
        </el-col>
        <el-col :span="6" class="col line shadow">
          <Chart
            :chartId="`client_third_line${index}`"
            :option="
              new LineOption({
                title: `Traffic of IP ${clientIp} (pps)`,
                yunit: 'pps',
                legendTitle: ['||Min||Max||Avg'],
                ipVersion,
                keepPoint: 2,
              }).getOption(['title', 'xAxis', 'yAxis', 'grid', 'series', 'tooltip', 'legend'])
            "
            :extraOption="lineLegendPacket[source.name]"
            :series="source.packet_result"
            :EventList="{
              legendScroll: handleLegend,
            }"
            :watchSeries="initPage"
          ></Chart>
        </el-col>
      </el-row>
      <div class="table-box shadow">
        <PaginationTable
          :loading="loading"
          :id="source.name"
          :originData="source ? source.table : []"
          :showRule="showRule"
          :rowClick="rowClick"
          :defaultOrder="{ prop: 'byte_count', order: 'descending' }"
        ></PaginationTable>
      </div>
    </div>
  </div>
</template>

<script>
// import Chart from '@/submodules/components/Chart'
import TimeZone from '@/components/TimeZone'
import NumberCol from '@/components/NumberCol'
import { getAppInfoById } from '@/api/FlowApi'
import { getIpList } from '@/api/IpApi'
import { AppViewByClientLineOption, handleLegend, initPage } from '@/templates/chart/lineTemplate'
export default {
  name: 'ClientView',
  components: {
    TimeZone,
    // Chart,
    // PaginationTable,
  },
  created() {
    this.ipVersion = localStorage.getItem('ip_version') || 'nf'
    if (this.$route.params) {
      if (this.$route.params.clientIp) {
        this.clientIp = this.$route.params.clientIp
      }
    }
  },
  data() {
    return {
      loading: false,
      ipListLimit: 100,
      ipListOffset: 50,
      lineLegendByte: {},
      lineLegendPacket: {},
      lineLegendFlow: {},
      // defalutdateArray: [],
      dateArray: [],
      ipList: [],
      ipVersion: 'nf',
      LineOption: AppViewByClientLineOption,
      clientIp: '',
      showRule: [
        {
          prop: 'ip',
          label: 'IP',
          width: '130px',
        },
        {
          prop: 'name',
          label: 'App',
          width: '130px',
        },
        {
          prop: 'connection',
          label: 'Flow Amount',
          // width: '140px',
          sortable: 'custom',
          compare: (a, b) => a.connection - b.connection,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.connection
          },
        },
        {
          prop: 'packet_count',
          label: 'Packet',
          // width: '130px',
          sortable: 'custom',
          compare: (a, b) => a.packet_count - b.packet_count,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.packet_count
          },
        },
        {
          prop: 'byte_count',
          label: 'Byte',
          // width: '130px',
          sortable: 'custom',
          compare: (a, b) => a.byte_count - b.byte_count,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.byte_count
          },
        },
        {
          prop: 'Src_to_Dst_Packets',
          label: 'Client to Server Packets',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Src_to_Dst_Packets - b.Src_to_Dst_Packets,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_Packets
          },
        },
        {
          prop: 'Src_to_Dst_Bytes',
          label: 'Client to Server Bytes',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Src_to_Dst_Bytes - b.Src_to_Dst_Bytes,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_Bytes
          },
        },
        {
          prop: 'Dst_to_Src_Packets',
          label: 'Server to Client Packets',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Dst_to_Src_Packets - b.Dst_to_Src_Packets,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_Packets
          },
        },
        {
          prop: 'Dst_to_Src_Bytes',
          label: 'Server to Client Bytes',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Dst_to_Src_Bytes - b.Dst_to_Src_Bytes,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_Bytes
          },
        },
      ],
      DataZone: parseInt(new Date().getTimezoneOffset() / 60),
      ipOption: [],
      ipList: [],
      chartData: [
        {
          nickname: 'As Client',
          name: 'Client',
          byte_result: [],
          packet_result: [],
          flow_result: [],
          table: [],
        },
        {
          nickname: 'As Server',
          name: 'Server',
          byte_result: [],
          packet_result: [],
          flow_result: [],
          table: [],
        },
      ],
      line_head: {
        name: '||Min||Max||Avg',
        type: 'line',
        itemStyle: {
          color: '#00000000',
        },
        lineStyle: {
          color: '#00000000',
        },
        showSymbol: false,
      },
    }
  },
  mounted() {},
  props: {},
  methods: {
    handleLegend,
    initPage,
    searchIpList(queryString, cb) {
      const ipList = this.ipList
      const results = queryString ? ipList.filter(ip => ip.value.indexOf(queryString) === 0) : ipList
      cb(results.slice(0, this.ipListLimit))
    },
    async getIpListByLimit() {
      const ipList = await getIpList(this.ipVersion, ...this.dateArray, this.ipListLimit)
      this.ipList = ipList.map(ip => {
        return { value: ip }
      })
    },
    async getIpList() {
      const ipList = await getIpList(this.ipVersion, ...this.dateArray)
      this.ipList = ipList.map(ip => {
        return { value: ip }
      })
    },
    // getShowRule(name) {
    //   const showRule = [...this.showRule]
    //   showRule.splice(1, 0, {
    //     prop: 'name',
    //     label: name,
    //   })
    //   return showRule
    // },
    async getAppInfo() {
      this.loading = true
      this.sourceData = await getAppInfoById(this.ipVersion, this.clientIp, ...this.dateArray)
      const chartData = JSON.parse(JSON.stringify(this.sourceData))
      // console.debug('chartData', chartData)
      this.chartData = chartData.filter(item => item.table.length !== 0)
      // this.chartData[0].nickname = 'As Client'
      // this.chartData[1].nickname = 'As Server'
      // 隐藏protocol
      this.handleByteLine()
      this.handlePacketLine()
      this.handleFlowLine()
      this.handleTable()
      this.loading = false
    },
    handleByteLine() {
      this.handleLine('byte_result', 'lineLegendByte')
    },
    handlePacketLine() {
      this.handleLine('packet_result', 'lineLegendPacket')
    },
    handleFlowLine() {
      this.handleLine('flow_result', 'lineLegendFlow')
    },
    handleTable() {
      this.chartData = this.chartData.map(source => {
        source.table = source.table.map(td => {
          td.type = source.name
          return td
        })
        return source
      })
    },
    handleLine(data_result, lineLegend) {
      this.chartData = this.chartData.map(source => {
        if (source[data_result].length > 0) {
          const lengedOption = {
            id: 'data',
            selected: {},
            data: [],
            scrollDataIndex: 0,
          }
          source[data_result] = source[data_result].map((item, idx) => {
            lengedOption.selected[item.name] = idx < 2 && idx >= 0 ? true : false
            lengedOption.data.push(item.name)
            item.type = 'line'
            item.data = item.data.map(this.transformDateWithTimeZone)
            item.showSymbol = item.data.length < 10 ? true : false
            return item
          })

          this.preventChartError(source[data_result])
          source[data_result].push(this.line_head)
          this[lineLegend][source.name] = { legend: lengedOption }
        }
        return source
      })
    },
    transformDateWithTimeZone(lineitem) {
      lineitem[0] = lineitem[0] - this.DataZone * 60 * 60 * 1000
      return lineitem
    },
    preventChartError(series) {
      if (series && series.length === 1) {
        const tempObj = {}
        series[0].data.forEach(d => {
          tempObj[d[1]] = 1
        })
        // 为了防止折线图错误，且数据小于1时折线图错误
        if (Object.keys(tempObj).length === 1) {
          series.push({
            name: 'aa',
            data: [[series[0].data[0], 1]],
            type: 'line',
            showSymbol: false,
          })
        }
      }
    },
    dataChange(dateArray, DataZone) {
      this.dateArray = dateArray
      this.DataZone = DataZone
      this.getIpListByLimit()
      // this.getIpList()

      // this.defalutdateArray = [
      //   new Date(parseInt(dateArray[0]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      //   new Date(parseInt(dateArray[1]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      // ]
      if (this.clientIp) {
        this.getAppInfo()
      }
    },
    selectDateZone(newDateZone, dateArray) {
      this.DataZone = newDateZone
      this.dateArray = dateArray
      // this.defalutdateArray = [
      //   new Date(parseInt(dateArray[0]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      //   new Date(parseInt(dateArray[1]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      // ]
      if (this.clientIp) {
        this.getAppInfo()
      }
    },
    rowClick(row, column, event) {
      this.$router.push({
        path: `/flow_view/`,
        query: {
          appName: row.name,
          ip: row.ip,
          // type: row.type,
        },
      })
    },
    searchByIp() {
      this.getAppInfo()
    },
  },
}
</script>

<style lang="scss" scoped>
.col {
  background-color: #fff;
}

.top-title {
  background: #fff;
  margin: 10px 0;
  margin-bottom: 10px;
  padding-left: 10px;
  color: #8b42a5;
  text-align: left;
  font-size: var(--subTitle-font-size);
}

.line {
  width: 33%;
  height: 50vh;
  padding: 5px;
}
</style>
